import React from 'react';
import ColorSlider from "./ColorSlider";

import PaletteDisplayCanvas from "./PaletteDisplayCanvas";
import Palettes from "./Palettes";

export default (props)=>{
  return (
    <div className="palette_list">
      <div className="palette_list_item" key="custom">
        <button onClick={()=>{props.files.prompt_open_palette_file()}}>load from file</button>
      </div>
      <div> or pick one of these:</div>
      {
        Palettes.Names().map(key=>{
          const canvas_id = "palette_canvas_"+key;
          return (
            <div className="palette_list_item" key={key}>
              <PaletteDisplayCanvas
                palette={Palettes.Get(key)}
                palette_name={key}
                canvas_id={canvas_id}
                label={key}
                onclick={()=>{props.callback_set_active_palette(key)}}
              />
            </div>
          );
        })
      }
    </div>
  )

}
