import palettes_raw from "./palettes.json";

let unadded_keys = Object.keys(palettes_raw);
let palettes = [];
while( unadded_keys.length > 0 ){
  const pick_key = unadded_keys[ Math.floor(Math.random()*unadded_keys.length) ];
  unadded_keys = unadded_keys.filter(a=>a!=pick_key);
  palettes[pick_key] = palettes_raw[pick_key];
}

const initial_palette_name = Object.keys(palettes)[0];
// const initial_palette_name = "downwell";

// const initial_palette_name = Object.keys(palettes_raw)[
//   Math.floor(Math.random()*Object.keys(palettes_raw).length)
// ];

export default class Palettes{
  static Get_initial_palette(){
    return Palettes.Get(Palettes.Get_initial_palette_name())
  }
  static Get_initial_palette_name(){
    return initial_palette_name;
  }
  static Names(){
    return Object.keys(palettes);
  }
  static Get(key){
    return palettes[key];
  }
  static Color_equals(
    color_as_int_array_a, color_as_int_array_b
  ){
    return color_as_int_array_a.every(
      (a,index)=>a===color_as_int_array_b[index]
    )
  }
  // Does not include transparent pixels.
  static Get_colors_array_from_canvas( canvas ){
    const {width,height} = canvas;
    const context = canvas.getContext("2d");
    const colors = context.getImageData(0,0,width,height).data;
    const re = [];
    const n = colors.length;
    for(let i=0; i<n; i+=4){
      if(colors[i+3]===0){
        continue;
      }
      const color = [
        colors[i],
        colors[i+1],
        colors[i+2]
      ];
      if( ! re.some(a=>a[0]===color[0]&&a[1]===color[1]&&a[2]===color[2]) ){
        re.push( color );
      }
    }
    return re;
  }
}
