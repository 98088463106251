import React from 'react';
import assert from "assert";

import './App.css';
import logo_png from "./img/logo_5_x14.png";

import in_default_png from "./img/in_default.png";
import rex_logo from "./img/rex_logotype_white.png";
import PaletteDisplayCanvas from "./PaletteDisplayCanvas";
import ColorSlider from "./ColorSlider";
import FileStuff from "./FileStuff";
import Palettes from "./Palettes";
import Component_ColorPickDialogueBox from "./Component_ColorPickDialogueBox"
import Component_PaletteList from "./Component_PaletteList";
import Component_Ad from "./Component_Ad";
import Canvases from "./Canvases";
import Conversion from "./Conversion";
import Images from "./Images";
import MapEditorComponent from "./MapEditorComponent";
import config from "./config";
import Compare from "./Compare";



class App extends React.Component{
  constructor(){
    super();
    this.suggested_map=undefined;
    this.custom_map=undefined;
    this.logo_map=undefined;
    this.palette=JSON.parse(JSON.stringify(
      Palettes.Get_initial_palette()
    ));
    this.palette_name = Palettes.Get_initial_palette_name();
    this.magic_knob = 0;
    window.app = this;
  }
  state={
    // if non negative, color prompt will show
    opened_color_index: -1,
    ready:false,
    hack:0,
  }
  refresh_react(){
    this.setState({hack: this.state.hack+Math.random()});
  }

  componentDidMount(){
    this.source_canvas = document.getElementById("source_canvas");
    this.magicked_source_canvas = document.createElement("canvas");
    this.destination_canvas = document.getElementById("destination_canvas");
    this.logo_canvas = document.getElementById("logo_canvas");

    let pending = 2;
    Images.Build_image_from_src( logo_png ).then(image=>{
      this.image_logo = image;
      if(--pending===0){
        this.ready()
      }
    })
    Images.Build_image_from_src( in_default_png ).then(image=>{
      this.source_image = image;
      if(--pending===0){
        this.ready()
      }
    })

  }
  get_map(){
    if( this.custom_map !== undefined ){
      return this.custom_map;
    }else if(this.suggested_map !== undefined){
      return this.suggested_map
    }else{
      return undefined;
    }
  }
  set_custom_mapping(source_index,destination_index){
    const map = this.get_map();
    if( map === undefined ){
      console.error("Non-fatal error: set_custom_mapping map was undefined.");
      this.update_suggested_map();
      this.convert();
      this.refresh_react();
      return;
    }
    // need to invalidate this to prevent bug
    this.magic_knob = 0;
    map[source_index]=destination_index;
    this.custom_map = map;
    this.convert();
    this.refresh_react();
  }
  clear_suggested_map(){
    this.suggested_map = undefined;
  }
  clear_custom_map(){
    this.custom_map = undefined;
  }
  ready(){
    // Initialize logo
    Canvases.Apply_image_to_canvas(this.image_logo, this.logo_canvas);
    this.logo_original_canvas = Canvases.Build_canvas_from_image(
      this.image_logo
    );
    // Initialize source image
    this.refresh_source_canvas();
    // Initialize file handling
    this.files = new FileStuff(
      new_source_image=>{
        this.source_image = new_source_image;
        this.magic_knob=0;
        // Canvases.TEST_MAGIC(new_source_image);
        this.clear_custom_map();
        // remove the old suggested map so we don't try to apply it to the new image
        this.clear_suggested_map();
        this.refresh_source_canvas();
        this.refresh_react();
        this.update_suggested_map();
        this.convert();
        this.refresh_react();
      },
      palette=>{
        console.log("LOADED CUSTOM PALETTE:");
        console.log( JSON.stringify(palette) );
        this.palette = palette;
        this.palette_name = "custom";
        this.magic_knob=0;
        this.clear_custom_map();
        // remove the suggested map so we don't try to apply it to the new palette
        this.clear_suggested_map();
        this.refresh_react();
        this.update_suggested_map();
        this.convert();
        this.refresh_react();
      }
    );
    this.watchdog();
    this.update_suggested_map();
    this.convert();
    this.setState({ready:true});
  }
  watchdog(){
    // this.refresh_react();
    // if( this.get_map() === undefined ){
      // this.update_suggested_map().then(()=>{
      //   this.refresh_react();
      // })
    // }
    setTimeout( ()=>{this.watchdog()} , 1000 );
  }

  refresh_source_canvas(){
    Canvases.Apply_image_to_canvas(
      this.source_image, this.source_canvas
    );
    Canvases.Apply_image_to_canvas(
      this.source_image, this.magicked_source_canvas
    );
    Canvases.Apply_magic(
      parseFloat(this.magic_knob),
      this.magicked_source_canvas,
    )
  }


  ////////////// CONVERSION
  convert(){
    this.refresh_source_canvas();
    const map = this.get_map();
    // this may now be uneeded, as removed async code
    // there are some situations where multiple events are happening at once,
    // and the map gets invalidated before a delayed call to convert() happen
    if(map===undefined){
      return;
    }
    Conversion.Convert(
      this.source_canvas, this.destination_canvas,
      this.palette, map
    );
    // Convert the logo too!
    Conversion.Convert(
      this.logo_original_canvas, this.logo_canvas,
      this.palette, this.logo_map
    );
  }
  // magic only affects the suggested palette
  update_suggested_map(){
    this.refresh_source_canvas();
    // indicate that we're waiting
    // this doesn't actually work any more, leaving it in case i re-add async code
    this.get_active_palette_canvas().classList.add("wobbling");
    document.getElementById("map_editor_canvas").classList.add("wobbling");
    const magicked_palette = Palettes.Get_colors_array_from_canvas(
      this.magicked_source_canvas
    );
    const result = Compare.Get_map(
      magicked_palette,
      this.palette
    );
    const magicked_map = result.map;
    // now build the map for the unmagicked palette
    const vanilla_palette = Palettes.Get_colors_array_from_canvas(
      this.source_canvas
    );
    // console.log( "vanilla_palette",vanilla_palette,"magicked_palette",magicked_palette,"magicked_map",magicked_map );
    let vanilla_map = [];
    for(let i=0; i<vanilla_palette.length;++i){
      const vanilla_color = vanilla_palette[i];
      const magicked_color = Canvases.Apply_magic_to_color(this.magic_knob, vanilla_color);
      // console.log("magicked_palette",magicked_palette)
      // console.log("magicked_color",magicked_color)
      const vanilla_index_in_magicked_palette = magicked_palette.indexOf(
        magicked_palette.find(a=>Palettes.Color_equals(
          a, magicked_color
        ))
      );
      // console.log(vanilla_index_in_magicked_palette);
      assert( vanilla_index_in_magicked_palette !== undefined );
      assert( vanilla_index_in_magicked_palette !== -1 );
      vanilla_map[i] = magicked_map[vanilla_index_in_magicked_palette];
    }


    this.get_active_palette_canvas().classList.remove("wobbling");
    document.getElementById("map_editor_canvas").classList.remove("wobbling");
    this.custom_map = vanilla_map;
    this.logo_map = result.logo_map;
  }


  //////////// PALETTE EDITING
  open_palette( palette_name ){
    this.magic_knob=0;
    // reset the custom map since we're opening a brand new palette
    this.clear_custom_map();
    // remove the suggested map so we don't try to apply it to the new palette
    this.clear_suggested_map();
    this.palette_name = palette_name;
    this.palette = JSON.parse(JSON.stringify(
      Palettes.Get(palette_name)
    ));
    this.update_suggested_map();
    this.convert();
    this.refresh_react();
  }
  get_active_palette_canvas(){
    return document.getElementById("active_palette_canvas");
  }
  set_color(value){
    this.palette[
      this.state.opened_color_index
    ] = value;
    this.update_suggested_map();
    this.convert();
    this.refresh_react();
  }
  set_opened_color_index( index ){
    // If the color was just closed
    if(index==-1){
      this.update_suggested_map();
      this.convert();
      this.refresh_react();
    }
    this.setState(
      {opened_color_index:index}
    );
  }
  get_opened_color(){
    const re = this.palette[this.state.opened_color_index];
    if(re===undefined){
      throw new Error();
    }
    return re;
  }

  on_active_palette_click( index ){
    this.setState({opened_color_index:index});
  }

  render(){
    return (
      <div className="App">
        <div className="top_bar">
          <div className="top_bar_row">
            <div className="top_left_corner">
              <Component_Ad />
            </div>
            <div className="top_center">
              <canvas id="logo_canvas"/>
            </div>
            <div className="top_right_corner">
              <span className="byline">by{'\u00A0'}</span>
              <a href={config.href_rex}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={rex_logo} />
              </a>
            </div>
          </div>
          <div className="top_bar_row">
            <PaletteDisplayCanvas
              canvas_id="active_palette_canvas"
              palette={this.palette}
              palette_name={this.palette_name}
              onclick={index=>{this.on_active_palette_click(index)}}
            />
          </div>
        </div>
        <canvas id="source_canvas" onClick={()=>{this.files.prompt_open_file()}}/>

          <MapEditorComponent
            source_palette={
              this.source_canvas!==undefined ?
              Palettes.Get_colors_array_from_canvas(
                this.source_canvas
              ) :
              []
            }
            magicked_source_palette={
              this.magicked_source_canvas!==undefined ?
              Palettes.Get_colors_array_from_canvas(
                this.magicked_source_canvas
              ) :
              []
            }
            destination_palette={
              this.palette
            }
            map={
              this.get_map()
            }
            magic={
              this.magic_knob
            }
            callback_set_mapping={
              (source_index,destination_index)=>{
                this.set_custom_mapping(source_index,destination_index)
              }
            }
          />
        {this.render_palette_browser()}

        <canvas id="destination_canvas" onClick={()=>{this.files.prompt_save_file(this.destination_canvas)}}/>
        {this.render_dialogue()}
      </div>
    );
  }
  render_dialogue(){
    if( this.state.opened_color_index!=-1){
      return <Component_ColorPickDialogueBox
        color={this.get_opened_color()}
        color_index={this.state.opened_color_index}
        callback_set_color={this.set_color.bind(this)}
        callback_do_close={()=>{this.set_opened_color_index(-1)}}
      />
    }
  }
  render_palette_browser(){
    return (
        <div className="palette_browser">
          <div>
            palettes
          </div>
          <Component_PaletteList
            files={this.files}
            callback_set_active_palette={this.open_palette.bind(this)}
          />
          <div className="drag_note">
            click it, drag it, drop it, try it!
          </div>
          <div className="magic_slider_container">
            this is a magic knob
            <input
              type="range" min="-200" max="200"
              value={this.magic_knob}
              onChange={event=>{
                this.magic_knob = parseFloat(event.target.value);
                // console.log( this.magic_knob );
                // remove the suggested map so we don't try to apply it to the newly adjusted source
                this.clear_suggested_map();
                // remove the handmade map, too, so we don't try to apply it to the newly adjusted source
                this.clear_custom_map();
                // console.log("a",this)
                this.update_suggested_map();
                  // console.log("b",this)
                this.convert();
                  // console.log("c",this)
                this.refresh_react();
              }}
            />
            <div className="litte_note">
              even we don't fully understand its power
            </div>
          </div>
        </div>
    );
  }
}

export default App;
