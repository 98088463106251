import React from 'react';

class PaletteDisplayCanvas extends React.Component{
  componentDidMount(){
    this.refresh_canvas();
  }
  refresh_canvas(){
    const {palette} = this.props;
    const canvas = document.getElementById( this.get_canvas_id() );
    const n = palette.length;
    canvas.width = n;
    canvas.height = 1;
    const context = canvas.getContext("2d");
    for(let i=0;i<n;++i){
      context.fillStyle = "rgb("+palette[i].join(",")+")";
      context.fillRect(i,0,1,1);
    }
  }
  get_canvas_id(){
    return this.props.canvas_id
  }
  // shouldComponentUpdate(next_props, next_state){
  //   return next_props.palette_name!==this.props.palette_name;
  // }
  componentDidUpdate(){
    this.refresh_canvas();
    if(this.props.on_componentDidUpdate!==undefined){
      this.props.on_componentDidUpdate.call()
    }
  }
  render_label(){
    if( this.props.label!==undefined ){
      return (<header>{this.props.label}</header>);
    }else{
      return (<></>)
    }
  }
  get_clicked_index( mouseevent ){
    const n = this.props.palette.length;
    const x = (
      mouseevent.clientX -
      mouseevent.target.getBoundingClientRect().left
    );
    const w = mouseevent.target.clientWidth;
    return Math.floor(n*x/w);
  }
  render(){
    return (
      <div className="PaletteDisplayCanvas">
        {this.render_label()}
        <canvas
          id={this.get_canvas_id()} onClick={event=>{
          this.props.onclick.call(null, this.get_clicked_index(event) )
        }}
          title={this.props.palette_name}
        />
      </div>
    );
  }
}

export default PaletteDisplayCanvas;
