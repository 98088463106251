import assert from "assert"
import Color from "./Color"

// Higher weight means the difference matters more.
const weight_rgb = [8,8,8];
const weight_hsv = [2,0,10];
const weight_yuv = [4,4,4];

const highest_possible_score = Number.MAX_SAFE_INTEGER;

// Based on weighted manhattan differences
function get_score(color_a, color_b){
  const a_rgb = color_a.rgb;
  const b_rgb = color_b.rgb;
  const a_hsv = color_a.hsv;
  const b_hsv = color_b.hsv;
  const a_yuv = color_a.yuv;
  const b_yuv = color_b.yuv;
  let score = 0;
  for(let i=0;i<3;++i){
    score += weight_rgb[i] * Math.abs( a_rgb[i]-b_rgb[i] );
    score += weight_hsv[i] * Math.abs( a_hsv[i]-b_hsv[i] );
    score += weight_yuv[i] * Math.abs( a_yuv[i]-b_yuv[i] );
  }
  return score;
}

function get_closest_match(color, destination_palette){
  let lowest_score=highest_possible_score+1;
  let lowest_index=0;
  const n = destination_palette.length;
  for(let i=0; i<n; ++i){
    const score = get_score(color, destination_palette[i]);
    if( score < lowest_score){
      lowest_score = score;
      lowest_index = i;
    }
  }
  return lowest_index;
}

export default class{
  static Get_map(source_palette, destination_palette){
    assert( typeof(source_palette) === typeof([]) );
    assert( typeof(destination_palette) === typeof([]) );
    // assert( source_palette.length > 0 && source_palette.length <= 128);
    // assert( destination_palette.length > 0 && destination_palette.length <= 128);
    for( let item of [ ...destination_palette , ...destination_palette ]  ){
      assert(typeof(item)===typeof[] );
      assert(item.length===3);
      for(let i=0; i<3; ++i){
        assert( typeof(item[i])===typeof(2) );
        assert( item[i] >= 0 && item[i] <= 255 );
      }
    }
    destination_palette = destination_palette.map(a=>new Color(a));
    const map = source_palette.map(
      color=>get_closest_match(new Color(color), destination_palette)
    );
    const logo_map = [[0,0,0],[255,255,255]].map(
      color=>get_closest_match(new Color(color), destination_palette)
    );
    return {map, logo_map} ;
  }
}
