
const assert = require("assert");
// const Random = require("../math/Random.js");

// Has a default alpha value of 255. If you set the color by rgb, hsv, etc (as opposed to rgba, hsva, etc) then the alpha value will not be affected.
class Color{

    // The object can be initialized using an existing RGB or RGBA array.
    constructor(init_rgb_or_rgba=[]){
      this._rgba = [0,0,0,255];
      if(init_rgb_or_rgba.length==0){
        // This is the most common path, so we're putting the return up here to save time.
        return;
      }else if(init_rgb_or_rgba.length==3){
        this.rgb = init_rgb_or_rgba;
      }else if(init_rgb_or_rgba.length==4){
        this.rgba = init_rgb_or_rgba;
      }else if(init_rgb_or_rgba.length!=0){
        throw new Error("Tried to initialize Color with an array that's not length 3 or 4.");
      }
    }
    copy(deep=true){
      if(this.is_erase()){
        // If the object has the _is_erase property,
        // make sure to pass it on to the copy.
        const re = new Color(this.rgba);
        re._is_erase = true;
        return re;
      }else{
        return new Color(this.rgba);
      }
    }
    equals(b){
      assert(b instanceof Color);
      return this.r===b.r && this.g===b.g && this.b===b.b
       && this.a===b.a
       && this.is_erase()==b.is_erase();
    }



  /* The getters and setters below allow reading/writing
    to the color using a variety of color models.

    Because RGB is the  most common model, the underlying
    data is RGBA. This is also the reason for exposing
    getters/setters for, R, G, and B individually.*/
  set r(val){this._rgba[0]=val}
  get r(){return this._rgba[0]};
  set g(val){this._rgba[1]=val}
  get g(){return this._rgba[1]};
  set b(val){this._rgba[2]=val}
  get b(){return this._rgba[2]};
  set a(val){this._rgba[3]=val}
  get a(){return this._rgba[3]};
  get rgb(){return [this.r, this.g, this.b]};
  set rgb(arr){this.r=arr[0]; this.g=arr[1]; this.b=arr[2]}
  get rgba(){return [this.r, this.g, this.b, this.a]};
  // set rgba(arr){this.r=arr[0]; this.g=arr[1]; this.b=arr[2], this.a=arr[3]}

  __rgb( arr ){
    this.rgb = arr;
    return this;
  }

  // Output is in standard HSV format acc'd to wikipedia, which is 0-360 (360 exclusive), 0-1, 0-1,
  get hsv(){
    // This getter just does what's described in the section of this article:
    // https://en.wikipedia.org/wiki/HSL_and_HSV#From_RGB
    let r = this.r;
    let g = this.g;
    let b = this.b;
    if(r==g && g==b && b==r){
      //Colorless, just return the lightness.
      // (note that the hue is actually "undefined", but we'll just
      // throw a 0 on there as the default hue value)
      return [0, 0, r];
    }

    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let avg = (max+min)/2;
    let range = max-min;
    let sum = max+min;

    let val = max;
    let sat = (max==0) ? max : range/max;
    let hue;
    switch(max){
      case r:
        hue = 60 * ( 0 + (g-b)/range );
        break;
      case g:
        hue = 60 * (2 + (b-r)/range );
        break;
      case b:
        hue = 60 * (4 + (r-g)/range );
        break;
    }
    while(hue<0){
      hue += 360;
    }

    return [hue, sat, val];
  };
  set hsv(arr){
    //https://en.wikipedia.org/wiki/HSL_and_HSV#HSV_to_RGB
    // using the algorithm described in https://en.wikipedia.org/wiki/HSL_and_HSV#HSV_to_RGB_alternative
    const h_divided_by_60 = arr[0]/60.0;
    const s = arr[1];
    const v = arr[2];
    this.r = Color._Hsv_to_rgb_helper_function(5, h_divided_by_60, s, v);
    this.g = Color._Hsv_to_rgb_helper_function(3, h_divided_by_60, s, v);
    this.b = Color._Hsv_to_rgb_helper_function(1, h_divided_by_60, s, v);
  }
  // Used in the hsv setter (above).
  //https://en.wikipedia.org/wiki/HSL_and_HSV#HSV_to_RGB_alternative
  static _Hsv_to_rgb_helper_function(n, h_divided_by_60, s, v){
    const k = (n + h_divided_by_60) % 6;
    return Math.round(255*(   v-v*s*Math.max( Math.min( k, 4-k, 1 ), 0)   ));
  }
  __hsv( arr ){
    this.hsv = arr;
    return this;
  }
  get hsva(){
      let arr = this.hsv;
      arr[3]=this.a;
      return arr;
  };
  set hsva(arr){
    this.hsv = arr;
    this.a = arr[3];
  }
  /*
    YUV for BT.709 (not studio swing)
    Output:
      y ranges from 0 to 1
      u ranges from -.436 to +.436
      v ranges from -.615 to +.615
  */
  get yuv(){
    // en.wikipedia.org/wiki/YUV
    // using the matrix from the section: HDTV with BT.709
    const r = this.r/255.0;
    const g = this.g/255.0;
    const b = this.b/255.0;
    const y = ( 0.2126*r + 0.7152*g +  0.0722*b );
  	const u = ( -0.09991*r + -0.33609*g + 0.436*b );
  	const v = ( 0.615*r + -0.55861*g + -0.05639*b );
  	return [y, u, v];
  }
  set yuv(arr){
    // en.wikipedia.org/wiki/YUV
    // using the matrix from the section: HDTV with BT.709
    const y = arr[0];
    const u = arr[1];
    const v = arr[2];
    this.r = Math.round(  255 * ( y + 0 +  1.28033*v )  );
  	this.g = Math.round(  255 * ( y + -.21482*u + -0.38059*v )  );
  	this.b = Math.round(  255 * ( y + 2.12798*u + 0 )  );
  }
  __yuv( arr ){
    this.yuv = arr;
    return this;
  }
  get yuva(){
      let arr = this.yuv;
      arr[3]=this.a;
      return arr;
  };
  set yuva(arr){
    this.yuv = arr;
    this.a = arr[3];
  }

  get hex(){
    // Simple string concatenation.
    return "#"
     + this.r.toString(16).padStart(2,"0")
     + this.g.toString(16).padStart(2,"0")
     + this.b.toString(16).padStart(2,"0");
  }
  set hex(str){
    // Remove a leading # if it exists.
    if(str.startsWith("#")){
      str = str.substring(1);
    }
    // Convert values like F00 to FF0000
    if( str.length != 6 ){
      if( str.length == 3){
        str = str.charAt(0)+str.charAt(0)+str.charAt(1)+str.charAt(1)+str.charAt(2)+str.charAt(2);
      }else{
        throw new Error("Invalid hex color: (#) "+str);
      }
    }
    const r = parseInt(str.substring(0,2),16);
    const g = parseInt(str.substring(2,4),16);
    const b = parseInt(str.substring(4,6),16);

    // if(isNaN(r) || isNaN(g) || isNaN(b)){
    //   throw new Error("Invalid hex color: (#) "+str);
    // }
    // Setting the values at the end so that a bad set doesn't affect the underlying data.
    this.r = r;
    this.g = g;
    this.b = b;
  }
  __hex( str ){
    this.hex = str;
    return this;
  }
  get hexa(){
    // Builds on the hex getter by adding alpha to the end
    return this.hex + this.a.toString(16).padStart(2,"0");
  };
  set hexa(str){
    // First pull the alpha value off of the end
    const a_st_idx = str.length-2;
    const a = parseInt(str.substring(a_st_idx), 16);
    // Then re-use our previous work by setting the hex using the rest of the string.
    this.hex = str.substring(0,a_st_idx);
    // if(isNan(a)){
    //   throw new Error("Invalid hex color: (#) "+str);
    // }
    // Set the alpha here so that you don't have a partially set value in case of an error.
    this.a = a;
  }

  /**
  Private field _is_erase:
  By default, this field is left undefined, for performance reasons.
  If _is_erase === true, then we consider the color to be an "erase" type color
  Otherwise, we consider the color to not be an "erase" type color.
  */
  is_erase(){
    return this._is_erase===true;
  }

  // A constant(-like) color representing erasure.
  static get ERASE(){
    const re = new Color([255,255,255,255]);
    re._is_erase = true;
    return re;
  }

  rgb_inverse(){
    const r = 255 - this.r;
    const g = 255 - this.g;
    const b = 255 - this.b;
    const re = new Color();
    re.r = r;
    re.g = g;
    re.b = b;
    re.a = this.a;
    return re;
  }

  create_snapshot(){
    return this._rgba.reduce( (acc, cur) => acc+" "+cur );
  }
  static From_snapshot( snapshot ){
    return new Color(  snapshot.split(" ").map( c=>parseInt(c) )  );
  }
  // static Random(){
  //   return new Color( [Random.Int_range_inclusive(0,255),Random.Int_range_inclusive(0,255),Random.Int_range_inclusive(0,255)] );
  // }
}
export default Color
