export default class Images{
  static async Build_image_from_src( src ){
    return new Promise((accept,reject)=>{
      const image = new Image();
      image.onload = ()=>{
        accept( image )
      };
      image.src = src;
    });
  }
}
