

export default class Canvases{
  static Apply_image_to_canvas(image,canvas){
    const {width,height} = image;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.clearRect( 0,0, width,height );
    context.drawImage(image, 0,0 );
  }
  // // Doesn't change the size of the canvas
  // static Re_apply_image_to_canvas(image,canvas){
  //   let context = this.in_canvas.getContext("2d");
  //   context.clearRect( 0,0, width,height );
  //   context.drawImage(image, 0,0 );
  // }
  static Build_canvas_from_image(image){
    const canvas = document.createElement("canvas");
    Canvases.Apply_image_to_canvas(image,canvas);
    return canvas;
  }
  static Build_canvas_from_color(color_as_int_array){
    const canvas = document.createElement("canvas");
    canvas.width = 1;
    canvas.height = 1;
    const context = canvas.getContext("2d");
    context.fillStyle = `rgb(${color_as_int_array.join(",")})`;
    context.fillRect( 0,0, 1,1 );
    return canvas;
  }
  static TEST_MAGIC(source_image){
    console.error("TEST START")
    const canvas = Canvases.Build_canvas_from_image(source_image);
    const canvas_2 = Canvases.Build_canvas_from_image(source_image);


    const bad = 105552;

    const{width,height}=canvas;

    const context = canvas.getContext("2d");
    const context_2 = canvas_2.getContext("2d");
        context.imageSmoothingEnabled=false;
        context_2.imageSmoothingEnabled=false;

    const image_data_2_pre = context_2.getImageData(0,0,width,height).data;

    Canvases.Apply_magic(200, canvas_2);

    const image_data = context.getImageData(0,0,width,height).data;
    const image_data_2 = context_2.getImageData(0,0,width,height).data;

    // console.log(image_data[bad],image_data[bad+1],image_data[bad+2])
    console.log([image_data_2[bad],image_data_2[bad+1],image_data_2[bad+2]])
    // console.log(image_data_2_pre[bad],image_data_2_pre[bad+1],image_data_2_pre[bad+2])

    console.log(Canvases.Apply_magic_to_color(
      200,
      [image_data[bad],image_data[bad+1],image_data[bad+2]]
    ));
    // console.log(Canvases.Apply_magic_to_color(
    //   200,
    //   [image_data_2_pre[bad],image_data_2_pre[bad+1],image_data_2_pre[bad+2]]
    // ));

    throw new Error();

    const n = image_data.length;
    console.error(n);
    for(let i=0;i<n;i+=4){
      const orig_color = [
        image_data[i],
        image_data[i+1],
        image_data[i+2],
      ];
      const color_1 = Canvases.Apply_magic_to_color(200, orig_color);
      const color_2 = [
        image_data_2[i],
        image_data_2[i+1],
        image_data_2[i+2]
      ]

      for(let c=0; c<3; ++c){
        if(
          color_1[c]!==color_2[c]
        ){
          console.error("MISMATCH:")
          console.error(i)
          console.error(orig_color)
          console.error(color_1)
          console.error(color_2)
          console.error(image_data)
          console.error(image_data_2)
          throw new Error();
        }
      }

      // console.error(orig_color)
      if( orig_color[0]===42){
        console.error("COLOR:")
        console.error(
          orig_color, color_1, color_2
        );
      }
    }
    console.error("TEST PASS");

  }
  // A copy paste of Apply_magic
  static Apply_magic_to_color(magic, color_as_int_array){
    let re = [...color_as_int_array]
    if(Math.abs(magic)<5){
      return color_as_int_array;
    }
    const contrast = magic > 0 ? magic / 140.0 : magic / -50.0;
    const red = magic > 0 ? magic / 420.0 : 0;
    const green = magic > 0 ? 0 : magic / -666;
    const blue = magic > 0 ? magic / 69.0 : magic / -250.0;
    const rgb = [red, green, blue];
    // console.log(rgb);
    for(let c=0;c<3;++c){
      let component = re[c];
      if( component < 128 ){
        component *= (1.0+contrast)
      }else{
        component *= (1.0-contrast)
      }
      component += rgb[c] * 50;
      if( magic < -150){
        component = 255+2*(150+magic) - component;
      }
      if( magic > 150){
        component -= rgb[c] * (magic-150)*(magic-150);
      }
      // console.log(component);
      re[c] = Math.max(0,Math.min(255,Math.floor( component )))
      // console.log("END",re[c]);
    }
    return re;
  }
  static Apply_magic(magic, canvas){
    const {width,height} = canvas;
    const context = canvas.getContext("2d")
    context.imageSmoothingEnabled=false;
    const image_data = context.getImageData(0,0,width,height);
    const data = image_data.data;
    const n = data.length;
    // console.log(magic);
    if(Math.abs(magic)<5){
      return;
    }
    const contrast = magic > 0 ? magic / 140.0 : magic / -50.0;
    const red = magic > 0 ? magic / 420.0 : 0;
    const green = magic > 0 ? 0 : magic / -666;
    const blue = magic > 0 ? magic / 69.0 : magic / -250.0;
    const rgb = [red, green, blue];
      // console.log(magic)
    // console.log(rgb)
    // const contrast = .5
    for(let i=0;i<n;i+=4){
      if( data[i+3] === 0 ){
        continue;
      }
      // force everything to fully or not at all transparent
      // otherwise, browser optimization will change the color (alpha premultiply issue)
      data[i+3] = 255;
      for(let c=0;c<3;++c){
        let component = data[i+c];
        if( component < 128 ){
          component *= (1.0+contrast)
        }else{
          component *= (1.0-contrast)
        }
        component += rgb[c] * 50;
        if( magic < -150){
          component = 255+2*(150+magic) - component;
        }
        if( magic > 150){
          component -= rgb[c] * (magic-150)*(magic-150);
        }
        data[i+c] = Math.max(0,Math.min(255,Math.floor( component )));
      }
    }
    context.putImageData(image_data, 0,0);
    // const d2 = context.getImageData(0,0,width,height);
    // console.log(d2.data)
    // for( let i=0;i<d2.length;++i){
    //   if(d2[i]!==0){
    //     console.log(d2[i])
    //   }
    // }
  }
}
