import React from 'react';


class ColorSlider extends React.Component{
  componentDidMount(){
    // this.refresh_canvas();
  }
  onchange(event){
    event.target.value = Math.max(0,Math.min(255,parseInt(event.target.value)));
    this.props.onchange.call(null, event.target.value);
  }
  render(){
    return (
      <div className="ColorSlider">
        <input className="textbox" type="text" value={this.props.value} onChange={event=>{this.onchange(event)}} />
        <input className="slider" type="range" min="0" max="255" value={this.props.value} onChange={event=>{this.onchange(event)}}/>
      </div>
    );
  }
}

export default ColorSlider;
