import React from "react";
import {useState} from 'react';
import ColorSlider from "./ColorSlider";

export default (props)=>{
  const style={
    backgroundColor: "rgb("+props.color.join(",")+")"
  }
  const set_color_component=(idx, int255)=>{
    const color = props.color;
    color[idx]=int255;
    props.callback_set_color(color);
  }
  return (
    <div className="dialogue_container" id="dialogue_container" onClick={()=>{
      props.callback_do_close()
    }}>
    <div className="dialogue" style={style} onClick={e=>e.stopPropagation()}>
      <ColorSlider value={props.color[0]} onchange={
        int255=>{set_color_component(0, parseInt(int255))}
      }/>
      <ColorSlider value={props.color[1]} onchange={
        int255=>{set_color_component(1, parseInt(int255))}
      }/>
      <ColorSlider value={props.color[2]} onchange={
        int255=>{set_color_component(2, parseInt(int255))}
      }/>
      <button style={style} onClick={()=>{
        props.callback_do_close()
      }}> OK </button>
    </div>
    </div>
  );
}
