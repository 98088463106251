import React from "react";
import {useState} from 'react';
import ColorSlider from "./ColorSlider";
import PaletteDisplayCanvas from "./PaletteDisplayCanvas";
export default (props)=>{

  return (
    <div className="dialogue_container dark"
    id="dialogue_container"
    onClick={()=>{
      props.callback_do_close();
    }}>
      <div className="palette_dialogue" onClick={
        e=>e.stopPropagation()
      }>
        <PaletteDisplayCanvas
          canvas_id="palette_choice_dialogue_canvas"
          palette={props.palette}
          onclick={index=>props.callback_choose(index)}
        />
      </div>
    </div>
  );
}
