import Axios from "axios";
import assert from "assert";
import Palettes from "./Palettes";
import config from "./config.json";

export default class Conversion{
  static async Request_map(source_palette,destination_palette){
    assert(typeof(source_palette)===typeof([]));
    assert(typeof(destination_palette)===typeof([]));
    if( ! source_palette.concat(destination_palette).every(
      a=>typeof(a)===typeof([])&&a.length===3&&a.every(
        c=>typeof(c)===typeof(2)
      )
    ) ){
      console.error("source_palette",source_palette);
      console.error("destination_palette",destination_palette);
      throw Error("Conversion.Request_map: Bad palette argument.");
    }
    const result = await Axios.post(
      config.url_get_map,
      {source_palette, destination_palette}
    );
    const {map, logo_map} = result.data;
    return {map, logo_map};
  }
  static Convert(source_canvas, destination_canvas, destination_palette, map){
    assert(destination_palette!==undefined)
    assert(map!==undefined)
    assert(typeof(map)===typeof([2]) && typeof(map[0])===typeof(2))
    const {width,height} = source_canvas;
    destination_canvas.width = width;
    destination_canvas.height = height;
    const source_palette = Palettes.Get_colors_array_from_canvas(
      source_canvas
    );
    const image_data = source_canvas.getContext(
      "2d"
    ).getImageData(
      0,0,width,height
    );
    const data = image_data.data;
    const n = data.length;
    for(let i=0;i<n;i+=4){
      if( data[i+3] === 0 ){
        continue;
      }
      const source_color = [ data[i] , data[i+1] , data[i+2] ];
      const source_index = source_palette.indexOf(
        source_palette.find(a=>a[0]===source_color[0]&&a[1]===source_color[1]&&a[2]===source_color[2])
      );
      const converted_color = destination_palette[ map[source_index] ];
      if( converted_color === undefined ){
        console.log("+++++++++++");
        console.log( "source_color",source_color );
        console.log( "source_palette",source_palette );
        console.log( "source_index",source_index );
        console.log( "destination_palette",destination_palette );
        console.log(" map[source_index] ", map[source_index] );
        console.log( "map",map );
        return;
      }
      data[i] = converted_color[0];
      data[i+1] = converted_color[1];
      data[i+2] = converted_color[2];
    }
    destination_canvas.getContext(
      "2d"
    ).putImageData(
      image_data, 0,0
    );
  }
}
