import React from 'react';
// import assert from "assert";
import ads from "./ads.json";
const ad_names = Object.keys(ads);

// Fail faster
validate_ads();


let candidate_names = [];
ad_names.forEach(key=>{
  if( ads[key].enabled ){
    for(let i=0; i<ads[key].weight; ++i){
      candidate_names.push(key);
    }
  }
});

const chosen_ad_name = candidate_names[
  Math.floor(Math.random()*candidate_names.length)
];

const {weight, type, src, text, href, enabled} = ads[chosen_ad_name];

const ad_content={};
ad_content["text"] =(
  <a href={href} target="_blank" rel="noreferrer noopener">
    {text}
  </a>
);
ad_content["youtube"] = (
  <iframe
    className="video_frame"
    src={href}
    frameBorder="0" allowFullScreen={true}></iframe>
);
ad_content["image"] = (
  <a href={href} target="_blank" rel="noreferrer noopener">
    <img
      src={src}
    />
  </a>
);

export default (props)=>{
  return ad_content[type];
}




function validate_ads(){
  const valid_types = ["text", "image", "youtube"];
  Object.keys(ads).forEach(key=>{
    const ad = ads[key];
    if( ad.enabled===undefined ){
      throw new Error("Ad: "+key+": must have enabled property (set to true or false).");
    }
    if( ad.type===undefined ){
      throw new Error("Ad: "+key+": must have type property.");
    }
    if( !valid_types.includes(ad.type) ){
      throw new Error("Ad: "+key+": type \""+ad.type+"\" is invalid.");
    }
    if( ad.weight <= 0){
      throw new Error("Ad: "+key+": Weight must be above zero.");
    }
    if( ad.type === "text"){
      if(ad.text===undefined||ad.text===""){
        throw new Error("Ad: "+key+": requires property \"text\".");
      }
    }
    if( ad.type === "image"){
      if(ad.src===undefined||ad.src===""){
        throw new Error("Ad: "+key+": requires property \"src\".");
      }
      if(ad.href===undefined||ad.href===""){
        throw new Error("Ad: "+key+": requires property \"href\".");
      }
    }
    if( ad.type === "youtube"){
      if(ad.href===undefined||ad.href===""){
        throw new Error("Ad: "+key+": requires property \"href\".");
      }
    }
  });
}
