import assert from "assert"
import Palettes from "./Palettes";

export default class FileStuff{
  constructor( callback_receive_source_image, callback_receive_palette ){
    this.callback_receive_source_image=callback_receive_source_image
    this.callback_receive_palette=callback_receive_palette
    this.init_drag_uploader();
  }
  init_drag_uploader(){
    const div = document.createElement("div");
  	const can_use_drag = ( ("draggable" in div) || ("ondragstart" in div && "ondrop" in div) )  &&  ("FormData" in window)  &&  ("FileReader" in window);
    if( ! can_use_drag ){
      return;
    }
    document.addEventListener("dragover",event=>{
      event.dataTransfer.dropEffect = "copy";
      event.preventDefault();
    });
    document.addEventListener("drop", event=>{
      event.preventDefault();
      const {items} = event.dataTransfer;
      if( items.length > 1){
        console.error("TODO: add support for batch processing (drag n drop)");
      }else if(items.length > 0){
        this.process_opened_file(
          [ items[0].getAsFile() ]
        );
      }
    });
  }
  prompt_open_file(){
    var element = document.createElement("input");
    element.type = "file";
    element.onchange = ()=>{ this.process_opened_file(element.files) }
    element.click();
  }
  process_opened_file( files ){
    if( files.length===0 ){
      return;
    }
    if( files.length > 1){
      console.error("TODO: add support for batch processing");
      for(let i=0; i<files.length; ++i){
        const filename = files[i].name;
        const filesize =files[i].size;
        const mime_type = files[i].type;
      }

    }else{
      if( files[0].name.toLowerCase().endsWith(".gpl") ){
        this.process_opened_palette_file([ files[0] ]);
        return;
      }
      if( files[0].size > 50000000){
        console.error("File too big. Max size 500MB");
        return;
      }
      const url = URL.createObjectURL( files[0] );
      const image = new Image();
      image.onload =()=>{
        // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
        URL.revokeObjectURL( url );
        this.callback_receive_source_image.call(
          null, image
        );
      }
      image.src = url;
    }
  }
  // Note that filename doesn't work any more - FF disabled it in 2019 or so
  prompt_save_file( anything_with_toDataURL ){
    assert( anything_with_toDataURL !== undefined )
    const data_url = anything_with_toDataURL.toDataURL();
    const filename = "converted.png";
    let link = document.createElement("a");
    link.style.display="none";
    link.download = filename;
    link.href = data_url;
    // I don't think it needs to be in the body,
    // but leaving this as an idea in case there
    // are compatibility issues
    // document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);
  }
  prompt_open_palette_file(){
    var element = document.createElement("input");
    element.type = "file";
    element.onchange = ()=>{ this.process_opened_palette_file(element.files) }
    element.click();
  }
  process_opened_palette_file( files ){
    if( files.length==0 ){
      return;
    }
    if( files[0].size > 50000000){
      console.error("File too big. Max size 500MB");
      return;
    }
    if( files[0].name.toLowerCase().endsWith(".gpl") ){
      this.get_file_contents_plaintext( files[0]).then(text=>{
        const palette = text.split("\n").reduce(
          (palette,line)=>{
            if(
              Number.isInteger(parseInt(line.split("\t")[0]))
            ){
              palette.push([
                parseInt(line.split("\t")[0]),
                parseInt(line.split("\t")[1]),
                parseInt(line.split("\t")[2]),
              ]);
            }
            return palette;
          },
          []
        );
        this.callback_receive_palette.call(
          null, palette
        )
      });
      return;
    }
    const url = URL.createObjectURL( files[0] );
    const image = new Image();
    image.onload =()=>{
      // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
      URL.revokeObjectURL( url )
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage( image, 0,0, );

      const palette = Palettes.Get_colors_array_from_canvas(canvas);
      if( palette.length == 0){
        return;
      }
      this.callback_receive_palette.call(
        null, palette
      )
    }
    image.src = url;
  }
  async get_file_contents_plaintext( file ){
    return new Promise((accept,reject)=>{
      const reader  = new FileReader();
      reader.onloadend = ()=>{
        accept( reader.result);
      }
      reader.readAsText(file);
    });
  }
}
