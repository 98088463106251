import React from 'react';
import Component_PaletteItemPickDialogueBox from "./Component_PaletteItemPickDialogueBox";
import Canvases from "./Canvases"
import Palettes from "./Palettes"

class MapEditorComponent extends React.Component{
  state = {
    selected_index:-1,
  }
  componentDidMount(){
    this.refresh_canvas();
  }
  refresh_canvas(){
    const {source_palette, magicked_source_palette, destination_palette, map, magic} = this.props;
    window.debug_editor = this;
    window.debug_vars = {source_palette, magicked_source_palette, destination_palette, map, magic};
    // if( map!==undefined && source_palette.length!=map.length){
    //   console.error({source_palette,destination_palette,map});
    //   throw new Error("Bad map.");
    // }
    const canvas = document.getElementById("map_editor_canvas");
    const n = source_palette.length;
    canvas.width = 2;
    canvas.height = n;
    const context = canvas.getContext("2d");
    // console.log("s",source_palette, "m",magicked_source_palette,"d", destination_palette,"map", map, "m2",magic)
    for(let i=0;i<n;++i){
      context.fillStyle = "rgb("+source_palette[i].join(",")+")";
      context.fillRect(0,i,1,1);
      if(
        destination_palette.length!==0 &&
        map!==undefined &&
        map.length === magicked_source_palette.length // b/c async calls, sometimes you end up calling this in the middle of a change
      ){
        const magicked_source_color = Canvases.Apply_magic_to_color(
          magic,
          source_palette[i]
        );
        let index_in_map = magicked_source_palette.indexOf(
          magicked_source_palette.find(a=>Palettes.Color_equals(
            a, magicked_source_color
          ))
        );
        if( destination_palette[
          map[ index_in_map ]]===undefined){
            index_in_map = i % map.length;
          //   console.log("s",source_palette, "m",magicked_source_palette,"d", destination_palette,"map", map, "m2",magic)
          //   console.log(index_in_map)
          // console.log(source_palette[i])
          // console.log(magicked_source_color)
          // console.error("Non-fatal error - map value was undefined in editor component.");
          // continue;
        }
        context.fillStyle = "rgb("+destination_palette[
          map[ index_in_map ]
        ].join(",")+")";
        context.fillRect(1,i,1,1);
      }
    }
  }
  componentDidUpdate(){
    this.refresh_canvas();
  }
  get_clicked_index( mouseevent ){
    const n = this.props.source_palette.length;
    const y = (
      mouseevent.clientY -
      mouseevent.target.getBoundingClientRect().top
    );
    const h = mouseevent.target.clientHeight;
    return Math.floor(n*y/h);
  }
  render(){
    return (
      <div className="MapEditorComponent">
        <canvas
          id="map_editor_canvas" onClick={event=>{
            this.setState({
              selected_index:this.get_clicked_index(event)
            });
        }}
        />
        {
          this.state.selected_index!==-1 ?
          (<Component_PaletteItemPickDialogueBox
              palette={this.props.destination_palette}
              callback_choose={index=>{
                this.props.callback_set_mapping(
                  this.state.selected_index,
                  index
                );
                this.setState({selected_index:-1});
              }}
              callback_do_close={()=>{
                this.setState({selected_index:-1});
              }}
            />) :
          (<></>)
        }
      </div>
    );
  }
}

export default MapEditorComponent;
